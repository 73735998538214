<template>
  <div class="mb-7">
    <div class="row d-flex align-items-start mx-0">
      <b-overlay
        :show="file.progress !== 100"
        rounded="sm"
        opacity="0"
        class="custom-overlay"
      >
        <div
          :aria-hidden="true ? 'true' : null"
          v-on:mouseover="isHovering = true"
          v-on:mouseleave="isHovering = false"
          style="max-width:150px"
          class="my-4 mx-2"
        >
          <a
            href="javascript:void(0)"
            class="symbol symbol-45 symbol-light caption-bg"
          >
            <div class="symbol-label" style="width:150px; height:150px">
              <img
                :src="calculatedThumb"
                :style="file.progress !== 100 ? 'opacity: 0.3' : ''"
                style="width:150px; height:150px; object-fit:cover"
              />
            </div>
            <div class="caption-overlay my-2" v-if="canAddCaption">
              <b-form inline>
                <b-form-group>
                  <b-form-input
                    v-model="caption"
                    class="mb-2 mr-sm-2 mb-sm-0 text-center w-100"
                    placeholder="Caption Here"
                    @change="changeCaption($event)"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </div>
          </a>
          <div class="row d-flex justify-content-between mx-0">
            <div class="col-xl-9 px-2">
              <p class="font-size-sm">{{ file.filename }}</p>
            </div>
            <div class="col-xl-3 px-0 text-right">
              <a
                href="javascript:void(0)"
                @click="emitThumbDeletion()"
                v-if="isHovering"
              >
                <i class="fas fa-times-circle text-hover-danger"></i>
              </a>
            </div>
          </div>
        </div>
        <template #overlay>
          <div class="text-center">
            <b-progress style="width:130px" class="mb-5" :max="100">
              <b-progress-bar
                :value="file.progress"
                variant="primary"
              ></b-progress-bar>
            </b-progress>

            <b-button
              type="reset"
              @click="emitThumbCancel()"
              variant="secondary"
              >{{ $t("GENERAL.BUTTONS.CANCEL") }}</b-button
            >
          </div>
        </template>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenenricIntegratedThumb",

  props: {
    file: { type: Object, required: true },
    canAddCaption: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      caption: "",
      isHovering: false
    };
  },

  methods: {
    openLightbox: function() {
      this.$emit("open_lightbox", this.index);
    },
    emitThumbDeletion: function() {
      this.$emit("thumb_deleted", this.file);
    },
    emitThumbCancel: function() {
      this.$emit("thumb_cancelled", this.file);
    },
    changeCaption: function($event) {
      let obj = {
        asset_reservation_id: this.file.assetReservationId,
        caption: $event
      };
      this.$emit("caption_changed", obj);
    }
  },

  computed: {
    calculatedThumb: function() {
      if (this.file.type == 1) {
        return "/media/misc/processing-video.png";
      } else if (this.file.type == 2) {
        return this.file.dataURL;
      } else if (this.file.type >= 3) {
        return "/media/misc/download-asset.png";
      } else {
        return "/media/misc/download-asset.png";
      }
    }
  }
};
</script>
<style scoped>
.caption-bg {
  position: relative;
}
.caption-overlay {
  position: initial;
}
.caption-overlay .form-control {
  width: 150px !important;
  border: unset;
  height: unset;
  border-radius: unset;
}
</style>
